<template>
  <v-container fluid class="px-0">
    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-if="!tracker">
      <v-card flat>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle> Department </v-list-item-subtitle>
              <v-list-item-title>
                {{
                  `${studentPromotionDetails.program.value.organogram.office_name}`
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle> Program </v-list-item-subtitle>
              <v-list-item-title>
                {{
                  `${studentPromotionDetails.program.value.program_name_certificate}`
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle> Current Level </v-list-item-subtitle>
              <v-list-item-title>
                {{ `${studentPromotionDetails.level.value.level}` }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>

      <v-card color="grey lighten-4" flat tile>
        <v-card-text class="body-1">
          {{
            `Do You Want To Promote To ${
              studentPromotionDetails.level.value.level + 100
            }?`
          }}
        </v-card-text>
      </v-card>
      <v-footer app>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="secondary--text"
          dark
          @click="
            promoteLevelBtn(
              studentPromotionDetails.program.value.id,
              studentPromotionDetails.level.value.id
            )
          "
          :disabled="!studentPromotionDetails.level.value.id"
        >
          Promote
        </v-btn>
      </v-footer>
    </template>
    <template v-else>
      <v-row>
        <v-col>
          <v-alert v-if="response" text type="success">
            {{
              `Students in the Department of 
          ${
            studentPromotionDetails.program.value.organogram.office_name
          } offering ${
                studentPromotionDetails.program.value.program_name_certificate
              }  promoted successfully to ${
                studentPromotionDetails.level.value.level + 100
              }`
            }}
          </v-alert>
          <v-alert outlined type="error" v-else>
            Something Went Wrong. Call the Administrator Immediately.
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import { defineComponent, inject, reactive, toRefs } from "vue";
  import { useActions } from "vuex-composition-helpers";

  export default defineComponent({
    setup() {
      const studentPromotionDetails = inject("promoteDetails");
      const { promoteStudents } = useActions(["promoteStudents"]);

      const data = reactive({
        overlay: false,
        response: null,
        tracker: false,
      });

      const { overlay, response, tracker } = toRefs(data);

      const promoteLevelBtn = (program_id, level_id) => {
        overlay.value = true;
        promoteStudents({ program: program_id, level: level_id })
          .then(() => {
            response.value = true;
          })
          .catch(() => {
            response.value = false;
          })
          .finally(() => {
            overlay.value = false;
            tracker.value = true;
          });
      };
      return {
        studentPromotionDetails,
        promoteLevelBtn,
        ...toRefs(data),
      };
    },
  });
</script>
