<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">STUDENT </span>PROMOTION
    </PageHeader>

    <v-card outlined elevation="2">
      <v-card-title class="primary secondary--text" primary-title>
        LEVELS
      </v-card-title>
      <v-slide-y-transition class="py-0" color="primary" group tag="v-list">
        <template v-for="(item, index) in programData.data2">
          <v-divider v-if="index !== 0" :key="`${index}-divider`"></v-divider>
          <v-list-item :key="`${index}`">
            <v-row
              justify="center"
              align="start"
              :key="`course ${index}`"
              class="px-2"
            >
              <template v-if="item.program">
                <v-col lg="4">
                  <div class="caption grey--text pt-2">Department</div>
                  {{ `Department of ${item.program.organogram.office_name}` }}
                </v-col>
                <v-col lg="3">
                  <div class="caption grey--text pt-2">Program</div>
                  {{ item.program.program_name_certificate }}
                </v-col>
                <v-col>
                  <template v-for="(level, i) in item.levels">
                    <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
                    <v-row align="center" class="py-2" :key="`${i}-level`">
                      <v-col>{{ `Level ${level.level}` }}</v-col>

                      <v-col>
                        <div
                          v-if="
                            programData.data1.some(
                              (info) =>
                                info.program_id === item.program.id &&
                                info.level_id === level.id &&
                                info.promote
                            )
                          "
                        >
                          <v-btn disabled color="error">Promoted</v-btn>
                        </div>
                        <div v-else>
                          <v-btn
                            color="success"
                            @click="promoteStudentBtn(item.program, level)"
                          >
                            promote
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </template>
            </v-row>
          </v-list-item>
        </template>
      </v-slide-y-transition>
    </v-card>

    <v-navigation-drawer
      fixed
      v-model="script"
      :permanent="script"
      right
      width="35%"
      class="shadow"
      clipped
      elevation-19
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-toolbar color="primary" class="secondary--text">
          <v-toolbar-title>STUDENT LEVEL PROMOTION</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn class="secondary--text" icon @click="script = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <StudentPromotionDrawer v-if="script" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import { provide, reactive, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import StudentPromotionDrawer from "@/components/Student/StudentPromotionDrawer.vue";

  export default {
    components: { PageHeader, StudentPromotionDrawer },
    setup() {
      const { getPromoteStudents } = useActions(["getPromoteStudents"]);

      const { getters_promote_students } = useGetters([
        "getters_promote_students",
      ]);

      const levelData = reactive({
        deleteResponse: false,
        isLoaded: true,
        programData: {},
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        levelItem: "",
        script: false,
        program: {},
        level: {},
      });

      const { isLoaded, script, programData, program, level } = toRefs(levelData);

      getPromoteStudents().then(() => {
        isLoaded.value = false;
        programData.value = getters_promote_students.value;
      });

      const promoteStudentBtn = (programPromote, levelPromote) => {
        script.value = true;
        program.value = programPromote;
        level.value = levelPromote;
      };

      provide("promoteDetails", {
        program: program,
        level: level,
      });

      return {
        ...toRefs(levelData),
        promoteStudentBtn,
      };
    },
  };
</script>
