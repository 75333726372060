var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('PageHeader',[_c('span',{staticClass:"font-weight-light",attrs:{"slot":"courses"},slot:"courses"},[_vm._v("STUDENT ")]),_vm._v("PROMOTION ")]),_c('v-card',{attrs:{"outlined":"","elevation":"2"}},[_c('v-card-title',{staticClass:"primary secondary--text",attrs:{"primary-title":""}},[_vm._v(" LEVELS ")]),_c('v-slide-y-transition',{staticClass:"py-0",attrs:{"color":"primary","group":"","tag":"v-list"}},[_vm._l((_vm.programData.data2),function(item,index){return [(index !== 0)?_c('v-divider',{key:`${index}-divider`}):_vm._e(),_c('v-list-item',{key:`${index}`},[_c('v-row',{key:`course ${index}`,staticClass:"px-2",attrs:{"justify":"center","align":"start"}},[(item.program)?[_c('v-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"caption grey--text pt-2"},[_vm._v("Department")]),_vm._v(" "+_vm._s(`Department of ${item.program.organogram.office_name}`)+" ")]),_c('v-col',{attrs:{"lg":"3"}},[_c('div',{staticClass:"caption grey--text pt-2"},[_vm._v("Program")]),_vm._v(" "+_vm._s(item.program.program_name_certificate)+" ")]),_c('v-col',[_vm._l((item.levels),function(level,i){return [(i !== 0)?_c('v-divider',{key:`${i}-divider`}):_vm._e(),_c('v-row',{key:`${i}-level`,staticClass:"py-2",attrs:{"align":"center"}},[_c('v-col',[_vm._v(_vm._s(`Level ${level.level}`))]),_c('v-col',[(
                          _vm.programData.data1.some(
                            (info) =>
                              info.program_id === item.program.id &&
                              info.level_id === level.id &&
                              info.promote
                          )
                        )?_c('div',[_c('v-btn',{attrs:{"disabled":"","color":"error"}},[_vm._v("Promoted")])],1):_c('div',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.promoteStudentBtn(item.program, level)}}},[_vm._v(" promote ")])],1)])],1)]})],2)]:_vm._e()],2)],1)]})],2)],1),_c('v-navigation-drawer',{staticClass:"shadow",attrs:{"fixed":"","permanent":_vm.script,"right":"","width":"35%","clipped":"","elevation-19":""},model:{value:(_vm.script),callback:function ($$v) {_vm.script=$$v},expression:"script"}},[_c('div',{staticClass:"mt-15 d-flex justify-space-between"},[_c('v-toolbar',{staticClass:"secondary--text",attrs:{"color":"primary"}},[_c('v-toolbar-title',[_vm._v("STUDENT LEVEL PROMOTION")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"icon":""},on:{"click":function($event){_vm.script = false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1),(_vm.script)?_c('StudentPromotionDrawer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }